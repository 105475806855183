.newspage,
.news-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 144px;

  @media screen and (min-width: $smallDesktop) {
    padding: 0px 48px 0px 48px;
  }
}

.news-list {
  &__image-container {
    display: flex;
    height: 267px;
    width: 300px;
    position: relative;
    margin: auto;
    padding-top: 48px;
    overflow: hidden;

    @media screen and (min-width: $smallDesktop) {
      padding-top: 0px;
    }

    @media screen and (min-width: $largeDesktop) {
      width: 500px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 0px 24px;
    max-width: 388px;

    @media screen and (min-width: $smallDesktop) {
      padding: 0px 24px 0px 48px;
    }

    @media screen and (min-width: $largeDesktop) {
      max-width: 424px;
    }
  }

  &__heading {
    margin-bottom: 48px;
    padding: 0px 24px;
    color: $primaryColor;
    text-align: left;
    @include size(40);
    line-height: 1.2;
    font-weight: 700;

    @media screen and (min-width: $smallDesktop) {
      margin: 48px 0px;
      @include size(48);
      padding: 0px;
    }
  }

  &__title {
    margin-bottom: 2px;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }

  &__author {
    margin-bottom: 2px;
    color: $metadataColor;
  }

  &__divider {
    border-color: $secondaryColor;
    width: 320px;

    @media screen and (min-width: $tablet) {
      width: 375px;
    }

    @media screen and (min-width: 576px) {
      width: 540px;
    }

    @media screen and (min-width: $smallDesktop) {
      display: none;
    }
  }

  &__date {
    margin-top: 48px;
    padding: 0px 24px;
    color: $metadataColor;

    @media screen and (min-width: $smallDesktop) {
      margin-top: 4px;
      padding: 0px;
    }
  }

  &__summary {
    margin-top: 24px;
    width: 100%;
    font-weight: 500;
  }

  &__article {
    padding: 0px 0px 48px 0px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $smallDesktop) {
      flex-direction: row;
      margin-top: 48px;
      padding: 0px 0px 72px 0px;
      border-bottom: 1px solid $secondaryColor;

      &:first-of-type {
        border-top: 1px solid $secondaryColor;
        margin-top: 0px;
        padding: 48px 0px 72px 0px;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s;
    display: block;
    transform: scale(1);

    &:hover {
      transform: scale(1.1);
    }
  }

  &__button {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $smallDesktop) {
      flex-direction: row;
    }

    &:link {
      color: $textColor;
    }

    &:visited {
      color: $textColor;
    }

    &:hover {
      color: $textColor;
      background: none;
    }

    &:active {
      color: $textColor;
    }
  }
}

.newspage {
  &__heading {
    @include size(40);
    color: $primaryColor;
    padding: 0px 24px;
    font-weight: 700;

    @media screen and (min-width: $smallDesktop) {
      padding: 0px;
      margin-top: 48px;
      @include size(48);
    }
  }

  &__author {
    color: $metadataColor;
    padding: 0px 24px;

    @media screen and (min-width: $smallDesktop) {
      padding: 0px;
    }
  }

  &__date {
    color: $metadataColor;
    margin-bottom: 48px;
    padding: 0px 24px;

    @media screen and (min-width: $smallDesktop) {
      padding: 0px;
    }
  }

  &__image-container {
    display: flex;
    height: 267px;
    padding: 0px 12px;
    position: relative;
    overflow: hidden;

    @media screen and (min-width: $smallDesktop) {
      padding: 0px;
      height: 475px;
      width: 500px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s;
    display: block;
    transform: scale(1);

    &:hover {
      transform: scale(1.1);
    }
  }

  &__content {
    margin: 48px 0px 64px 0px;
    padding: 0px 24px;

    @media screen and (min-width: $smallDesktop) {
      padding: 0px;
    }
  }

  &__copy {
    max-width: 1130px;
    margin-bottom: 48px;

    p {
      margin-bottom: 16px;
      font-weight: 500;
    }

    a {
      color: $secondaryColor;

      &:hover {
        text-decoration: underline;
      }
    }

    h2 {
      margin: 16px 0px;
      color: $secondaryColor;
    }

    ul {
      margin: 16px 0px;
    }
  }

  &__cta {
    padding: 12px;
    text-decoration: none;
    display: block;
    width: 180px;
    @include size(16);
    margin-left: auto;
    margin-right: auto;
    border: none;
    background: $primaryColor;
    color: $textColor;
    border-radius: 8px;
    cursor: pointer;
  }
}
