.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 40px;
  padding: 48px 24px;

  @media screen and (min-width: $smallDesktop) {
    margin: 100px 40px;
    padding: 72px 84px 48px 84px;
  }

  &__image-content {
    width: 106px;
    height: 166px;
    margin-top: 32px;

    @media screen and (min-width: $largeDesktop) {
      margin-top: 64px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__description {
    margin: 40px 0px;
    @include size(24);
    text-align: center;

    @media screen and (min-width: $smallDesktop) {
      @include size(28);
    }
  }

  &__button {
    padding: 12px;
    text-decoration: none;
    display: block;
    width: 180px;
    @include size(16);
    margin-left: auto;
    margin-right: auto;
    border: none;
    background: $primaryColor;
    color: $textColor;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-size: 4px 20px;
      background: $primaryColorHover;
    }

    &:link {
      background: $primaryColorHover;
    }

    &:active {
      background: $primaryColorHover;
    }
  }

  ~ .footer {
    display: none;
  }
}
